.container {
  flex: 1;
}

.tabs {
  @media screen and (max-width: 1035px) {
    border-bottom: 1px solid #c8c8c8;
  }
  @media screen and (min-width: 1036px) {
    max-width: 240px;
  }
}
