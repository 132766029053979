.root {
  box-sizing: border-box;
}

.container {
  // PC
  @media screen and (min-width: 1036px) {
    border-radius: 4px;
    overflow: hidden;
  }
  // Mobile
  @media screen and (max-width: 1035px) {
    border-radius: 0;
  }
}

.edited {
  // PC
  @media screen and (min-width: 1036px) {
    outline: 2px solid #ff6478;
  }
  // Mobile
  @media screen and (max-width: 1035px) {
    border: 2px solid #ff6478;
  }
}

@mixin header {
  padding: 0 28px;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
}

.normalHeader {
  @include header;
  background-color: #fac83c;
  color: #5a5a5a;
}

.frozenHeader {
  @include header;
  background-color: #8cb4dc;
  color: #ffffff;
}

.titleContainer {
  padding: 20px 0;
}

.body {
  flex: 1 1 auto;
  background-color: #fff;
}
