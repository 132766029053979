.root {
  width: 100%;
  align-items: center;
}

.title {
  padding: 5px 10px;
  border-radius: 4px;
}
.image {
  position: relative !important;
  display: block;
  width: 100%;
  height: auto !important;
}

.imageContainer {
  width: calc(100% + 40px) !important;
}
