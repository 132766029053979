@mixin container {
  padding: 0px 28px 20px 28px;
  height: 100%;
  gap: 20px;
}

.normalContainer {
  @include container;
  background-color: #fac83c;
}

.frozenContainer {
  @include container;
  background-color: #8cb4dc;
}

.header {
  flex: 0 0 auto;
}

.body {
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  flex: 1 1 auto;
}

.amount {
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.nextNutrient {
  background-color: #fff5d2;
  padding: 20px 10px 10px;
}

.nutrientHeader {
  justify-content: center;
  padding: 0px 0px 12px;
}

.nutrientItems {
  padding: 0px 10px;
  & > div {
    margin-bottom: 10px;
  }
}

.showMoreNutrients {
  justify-content: center;
  margin-top: 4px;
  & > span {
    cursor: pointer;
    border-bottom: 1px solid;
  }
}

.subscriptionProducts {
  padding: 10px 20px 0;
  & > div:last-child {
    border-bottom: 1px solid #c8c8c8;
  }
}
.additionalProducts {
  padding: 0px 20px;
}
.additionalProductsCautions {
  margin: 20px 20px 0 20px;
}
