.link {
  text-decoration: underline !important;
}

.pointPlus {
  color: #47b881;
}

.pointMinus {
  color: #ff6478;
}
