.root {
  // PC
  @media screen and (min-width: 1036px) {
    max-height: 70vh;
    max-height: 70dvh;
  }

  // Mobile
  @media screen and (max-width: 1035px) {
    width: 100vw;
    height: 100vh;
    height: -webkit-fill-available;
    height: 100dvh;
  }
}

.header {
  padding: 20px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dddddd;
}

.body {
  align-items: center;
  // PC
  @media screen and (min-width: 1036px) {
    padding: 20px;
  }
  // Mobile
  @media screen and (max-width: 1035px) {
    padding: 4px;
  }
}

.chip {
  padding: 6px 8px;
  margin-right: 8px;
}

.arrowDown {
  display: block;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 13px 13px 0 13px;
  border-color: #fac83c transparent transparent transparent;
}

.modalFooter {
  gap: 10px;
}

.reasonSelectContainer {
  width: 100%;
}

.reasonSelect {
  width: 100%;
}

.closeIcon {
  display: block;
  margin-left: auto;
  cursor: pointer;
  width: 40px;
  height: 20px;
  position: relative;
  &:before {
    content: "";
    display: block;
    width: 20px;
    height: 3px;
    background-color: #5a5a5a;
    border-radius: 2px;
    position: absolute;
    left: 19px;
    top: 9.5px;
    transform: rotate(45deg);
  }
  &:after {
    content: "";
    display: block;
    height: 20px;
    width: 3px;
    background-color: #5a5a5a;
    border-radius: 2px;
    position: absolute;
    left: 27.5px;
    top: 1px;
    transform: rotate(45deg);
  }
}

.retentionContent {
  width: 100%;
  padding-top: 20px;
}
