.root {
  &:first-child > div:first-child {
    border-top: none;
  }
  & > div:first-child {
    border-top: 1px solid #c8c8c8;
  }
}

.newTag {
  margin-left: 4px;
}

.title {
  padding: 4px 0;
}
