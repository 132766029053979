.root {
  width: 100%;
  align-items: center;
}

.title {
  padding: 5px 10px;
  border-radius: 4px;
}

.image {
  position: relative !important;
  display: block;
  height: auto !important;
}

.imageContainer {
  width: 80%;
}

.hr {
  width: 100%;
  margin: 0;
}
