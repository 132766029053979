.root {
  width: 100%;
  align-items: center;
}

.title {
  padding: 5px 10px;
  border-radius: 4px;
}
.subtitle {
  line-height: 1.8;
}
.image {
  position: relative !important;
  display: block;
  width: 100%;
  height: auto !important;
}

.compareImageContainer {
  margin-bottom: 20px;
}

.graphImageContainer {
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
}

.markpen {
  padding: 5px 8px;
  margin-left: 5px;
  border-radius: 4px;
}
