.wrapper {
  & dl {
    display: table;
    width: 100%;

    dt,
    dd {
      display: table-cell;
      vertical-align: top;
      padding: 5px 0;
    }
  }
}
