.root {
  padding: 10px 30px;
}

.couponSection {
  padding: 0 0 20px;
  border-bottom: 1px dashed #c8c8c8;
}

.couponInput {
  margin-right: 8px;
}

.pointSection {
  padding: 10px 0 20px;
  border-bottom: 1px solid #c8c8c8;
}

.subTotalSection {
  padding: 12px 0 8px;
  border-bottom: 1px solid #c8c8c8;
}

.subTotalItem {
  justify-content: space-between;
  padding: 5px 0;
}

.totalSection {
  padding: 12px 0 8px;
}

.invalidPriceAlert {
  background-color: #00508c;
  border-radius: 4px;
  padding: 15px 10px;
  margin-top: 10px;
}
