.root {
  flex: 1;
  @media screen and (min-width: 1036px) {
    // col-xのマージンを打ち消す
    margin-left: -14px;
    margin-right: -14px;
  }
}

.borderTop {
  border-top: 1px solid #c8c8c8;
}

.productThumbnail {
  max-width: 100px;
  border: 1px solid #c5c5c5;
  padding: 5px;
  border-radius: 4px;
}

.productTitle {
  position: relative;
  width: 100%;
  padding-left: 10px;
  font-size: 14px;
}

.chip {
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.couponTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  dt {
    vertical-align: baseline !important;
  }
}
