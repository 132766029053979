.root {
  padding: 10px 28px;
  border-top: 1px solid #c8c8c8;
  background-color: #fff;
  width: 100%;
}

.body {
  display: flex;
  // PC
  @media screen and (min-width: 1036px) {
    flex-direction: row;
    justify-content: space-between;
  }

  // Mobile
  @media screen and (max-width: 1035px) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
}

.leftContainer {
  display: flex;
  & > span {
    line-height: 0.75rem;
    font-size: 0.75rem;
  }
  // PC
  @media screen and (min-width: 1036px) {
    flex-direction: row;
    align-items: center;
  }
  // Mobile
  @media screen and (max-width: 1035px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.rightContainer {
  display: grid;
  align-items: center;
  // PC
  @media screen and (min-width: 1036px) {
    grid-auto-flow: column;
    grid-gap: 20px;
  }
  // Mobile
  @media screen and (max-width: 1035px) {
    grid-auto-flow: row;
    grid-gap: 4px;
  }
}

.totalPriceContainer {
  padding: 12px 0;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
}

.totalPriceItemContainer {
  align-items: center;
}

.deletedPrice {
  margin-right: 5px;
}

.rankUpMilePopup {
  position: absolute;
  top: -36px;
  // SP
  @media screen and (max-width: 1035px) {
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}
.rankUpMilePopupMessage {
  color: #5a5a5a;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 20px;
  text-align: center;
  white-space: pre-wrap;
}

.buttonsContainer {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
}
