.nutrientItem {
  border-radius: 10px;
  overflow: hidden;
  justify-content: center;
}

.nutrientItemTitle {
  padding: 4px 20px;
  justify-content: center;
  background-color: #fac83c;
}

.nutrientItemBody {
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.nutrientItemImage {
  margin-right: 10px;
}

.amountContainer {
  align-items: baseline;
  margin-left: 8px;
  font-weight: bold;
  & > span {
    margin-right: 4px;
  }
}
