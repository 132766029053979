.root {
  width: 100%;
  align-items: center;
}

.title {
  padding: 5px 10px;
  border-radius: 4px;
}

.recommendItemImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    max-width: 60%;
  }
}

.image {
  position: relative !important;
  display: block;
  width: 100%;
  height: auto !important;
}

.batch {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  left: -10px;
  top: -10px;
  font-size: 14px;
  z-index: 1;
}

.recommendItem {
  position: relative;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  align-items: center;
  margin-bottom: 20px;
}

.recipeImageContainer {
  width: calc(100% + 40px) !important;
}
