.root {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.buttonStartIcon {
  margin-right: 8px;
}

.titleContainer {
  display: flex;
  @media screen and (min-width: 1036px) {
    flex-direction: row;
    align-items: center;
  }
  @media screen and (max-width: 1035px) {
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    margin-top: 12px;
  }
}

.backButton {
  margin-right: 14px;
  @media screen and (max-width: 1035px) {
    margin-bottom: 10px;
  }
}

.title {
  font-size: 1.25rem;
  font-weight: bold;
}

.header {
  margin-bottom: 20px;
}

.body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 28px;
  @media screen and (max-width: 1035px) {
    grid-template-columns: 1fr;
  }
}

.driverImage {
  position: relative;
  top: 8px;
}

.footer {
  padding: 40px 0;
  align-items: center;
  justify-content: center;
}

.bottomActions {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
