.root {
  padding: 20px 28px;
}
.latestOrder {
  align-items: flex-start;
}

.deliveryStatusChip {
  margin-left: 6px;
  margin-bottom: 12px;
}

.orderLink {
  display: inline-block;
  border-bottom: 1px solid;
}

.orderDivider {
  margin: 20px 0;
}

.fieldLabel {
  justify-content: space-between;
  margin-bottom: 8px;
}

.datePicker {
  margin-bottom: 10px !important;
}

.timeZoneSelector {
  flex: 1 1 auto;
}

.skipButton {
  margin-left: 8px;
}
.fieldSection {
  margin-bottom: 20px;
}

.policy {
  & > p {
    color: #828282;
    font-size: 0.75rem;
    & > a {
      color: #fac83c;
      text-decoration: underline;
    }
  }
}

.toolTipIcon {
  margin-left: 4px;
}

.warningNote {
  border-radius: 4px;
  padding: 10px;
}
