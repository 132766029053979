.root {
  flex: 1;
  @media screen and (min-width: 1036px) {
    // col-xのマージンを打ち消す
    margin-left: -14px;
    margin-right: -14px;
  }
}

.section {
  border-radius: 4px;
  padding: 20px 28px;
}

.borderTop {
  border-top: 1px solid #c8c8c8;
}

.image {
  max-width: 100px;
  border: 1px solid #c5c5c5;
  padding: 5px;
  border-radius: 4px;
}

.title {
  position: relative;
  width: 100%;
  padding-left: 10px;
  font-size: 14px;
}
