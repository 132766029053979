.amount {
  align-items: center;
  justify-content: center;
  & > div {
    padding: 0 10px;
    align-items: center;
  }
  & span {
    margin-left: 2px;
  }
}
