.root {
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.title {
  font-size: 1rem;
  padding: 5px 10px;
}

.bottomActions {
  width: 100%;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}

.otherReason {
  width: 100%;
}
