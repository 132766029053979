.root {
  padding: 20px 2px 24px;
  border-bottom: 1px dashed #c8c8c8;
  &:last-child {
    border-bottom: none;
  }
}

.itemHeading {
  margin-bottom: 6px;
}

.productThumbnail {
  max-width: 80px;
  max-height: 80px;
}

.productInfo {
  margin-left: 10px;
}

.unitPriceContainer {
  align-items: center;
}

.discountChip {
  margin-right: 10px;
}

.unitPrice {
  margin-right: 4px;
}

.itemDescription {
  margin-bottom: 6px;
}

.itemBottom {
  align-items: center;
  justify-content: space-between;
}

.itemBottomActions {
  align-items: center;
  & > * {
    margin-right: 4px;
  }
}

.newTag {
  margin-left: 4px;
}

.changedSelector {
  outline: 2px solid #ff6478 !important;
}

.annotation {
  white-space: pre-wrap;
}
