.root {
  width: 100%;
  align-items: center;
}

.bg {
  padding: 20px;
  border-radius: 10px;
  width: 100%;
}
.title {
  padding: 5px 10px;
  border-radius: 4px;
}
.img {
  position: relative !important;
  display: block;
  width: 100%;
  height: auto !important;
}

.list li {
  position: relative;
  padding-left: 35px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  font-weight: bold;
  &:first-child {
    margin-top: 0;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: auto;
    left: 0;
    margin: auto;
    width: 25px;
    height: 25px;
    background-color: #ff6478;
    border-radius: 50%;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 9px;
    width: 7px;
    height: 11px;
    transform: rotate(40deg);
    border-bottom: 3px solid #fff;
    border-right: 3px solid #fff;
  }
}
