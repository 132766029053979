.container {
  background-color: #fac83a;
  border-radius: 5px;
  display: flex;
  width: calc(100vw - 40px);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 20px;
  margin: auto;
  align-items: center;
  z-index: 10;
  justify-content: space-between;
  padding: 7.5px 20px;
  font-size: 14px;
}

.count {
  vertical-align: middle;
  display: inline-block;
  border-radius: 7px;
  min-width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  margin: 11px 5px 11px 0;
  padding: 0 5px;
}

.price {
  vertical-align: middle;
}

.link {
  border-bottom: 1px solid;
}
