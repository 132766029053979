.wrapper {
  display: block;

  table {
    width: 100%;

    thead {
      border-bottom: 1px solid #c8c8c8;

      th {
        border-top: 2px solid #c8c8c8;
        padding: 10px;
        background-color: #f0f0f0;
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #c8c8c8;
      }

      td {
        padding: 20px 10px;
        vertical-align: middle;
        border-bottom: 1px solid #c8c8c8;

        > * {
          vertical-align: middle;

          &:first-child {
            margin-right: 20px;
          }
        }
      }
    }
  }
}
