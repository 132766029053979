.root {
  width: 100%;
  align-items: center;
}

.bg {
  width: 100%;
  padding: 20px;
  border-radius: 10px;
}
.title {
  padding: 5px 10px;
  border-radius: 4px;
}

.image {
  position: relative !important;
  display: block;
  height: auto !important;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: unset !important;
  & > img {
    max-width: 80%;
  }
}

.flagImageContainer {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    width: auto !important;
    height: 100% !important;
  }
}

.markpen {
  padding: 5px 8px;
  margin-left: 5px;
  border-radius: 4px;
}

.hr {
  width: 100%;
  margin-bottom: 0;
  margin-top: 20px;
}

.articleSectionTitle {
  flex: "1 1 auto";
}
